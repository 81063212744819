import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const SEO = makeShortcode("SEO");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEO title="Vyrábíme sáčky, pytle, folie, hadice a polhoadice" description="@todo" mdxType="SEO" />
    <h1 {...{
      "id": "naše-vlastní-výroba"
    }}>{`Naše vlastní výroba`}</h1>
    <p>{`Zabýváme se výrobou:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#polypropylenov%C3%A9-s%C3%A1%C4%8Dky-a-pytle"
        }}>{`Polypropylenových (PP) sáčků a pytlů`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#polyethylenov%C3%A9-s%C3%A1%C4%8Dky-a-pytle"
        }}>{`Polyetylenových (LDPE) sáčků a pytlů`}</a>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#polypropylenov%C3%A9-folie-hadice-a-polohadice"
        }}>{`Polypropylenových folií, hadic a polohadic`}</a>{`.`}</li>
    </ul>
    <p>{`Způsob jejich využití je široký a doufáme, že každý zde nalezne vhodný obal.`}</p>
    <h2 {...{
      "id": "polypropylenové-sáčky-a-pytle"
    }}>{`Polypropylenové sáčky a pytle`}</h2>
    <p><a parentName="p" {...{
        "href": "/vyroba/polypropylenove-pp-sacky-a-pytle/"
      }}><strong parentName="a">{`Polypropylenové sáčky`}</strong></a>{` jsou velice žádané pro svoji `}<strong parentName="p">{`transparentnost`}</strong>{` a vysoký `}<strong parentName="p">{`lesk`}</strong>{`. Z tohoto důvodu se používají především při balení textilu. Pro snadné zavírání sáčků slouží `}<a parentName="p" {...{
        "href": "/technologie/parametry/klopa/"
      }}><strong parentName="a">{`klopa`}</strong></a>{` s `}<a parentName="p" {...{
        "href": "/technologie/parametry/lepici-paska/"
      }}><strong parentName="a">{`lepicí páskou`}</strong></a>{`, kterou vyrábíme v provedení trvale lepící – sáček nelze již znovu otevřít nebo `}<a parentName="p" {...{
        "href": "/technologie/parametry/lepici-paska/#Opakovateln%C3%A1"
      }}><strong parentName="a">{`opakovatelná`}</strong></a>{` – sáček můžete až 5x otevřít a zavřít. Pro potřebu zavěšení na stojany slouží `}<a parentName="p" {...{
        "href": "/technologie/parametry/raminko/"
      }}><strong parentName="a">{`ramínko`}</strong></a>{` navařené na dno sáčku a trvale lepící klopa zabraňující propadnutí výrobku.`}</p>
    <p>{`Další velice časté využití je v `}<strong parentName="p">{`potravinářství`}</strong>{`. Zde se používají PP sáčky především pro `}<a parentName="p" {...{
        "href": "/technologie/ukazky/baleni-peciva-a-cukrarskch-vyrobku"
      }}><strong parentName="a">{`balení pečiva a cukrářských výrobků`}</strong></a>{`. Pro pekaře vyrábíme sáčky navlečené na drátě a `}<a parentName="p" {...{
        "href": "/technologie/parametry/derovani/"
      }}><strong parentName="a">{`perforací`}</strong></a>{` pro odtržení. Nezbytností je též `}<a parentName="p" {...{
        "href": "/technologie/materialy/atest-pro-styk-s-potravinami/"
      }}><strong parentName="a">{`atest pro styk s potravinami`}</strong></a>{`.`}</p>
    <p>{`Ať již využijete sáček na výše zmíněné účely, či pro balení časopisů, CD, reklamních předmětů, papírenského zboží a mnoho dalších produktů, pokaždé bude Váš výrobek vynikat díky čirosti a lesku polypropylenového obalu.`}</p>
    <h2 {...{
      "id": "polyethylenové-sáčky-a-pytle"
    }}>{`Polyethylenové sáčky a pytle`}</h2>
    <p><a parentName="p" {...{
        "href": "/vyroba/polyethylenove-pe-sacky-a-pytle/"
      }}><strong parentName="a">{`Polyetylenové`}</strong></a>{` nebo též `}<a parentName="p" {...{
        "href": "/technologie/materialy/igelit/"
      }}><strong parentName="a">{`igelitové`}</strong></a>{` sáčky jsou `}<strong parentName="p">{`levným`}</strong>{` řešením pro každý výrobek. Jsou velice `}<strong parentName="p">{`odolné`}</strong>{`na tah a proti proražení. Jejich použití je vhodné téměř pro všechny druhy výrobků.`}</p>
    <p>{`U `}<a parentName="p" {...{
        "href": "/technologie/materialy/polyethylen-pe/"
      }}><strong parentName="a">{`LDPE`}</strong></a>{` sáčků jsme schopni vysekávat `}<a parentName="p" {...{
        "href": "/technologie/parametry/pruhmaty/"
      }}><strong parentName="a">{`průhmaty`}</strong></a>{` nebo vyrábět sáčky s `}<a parentName="p" {...{
        "href": "/technologie/parametry/prolozene-dno/"
      }}><strong parentName="a">{`bočním proložením`}</strong></a>{`. Specialitou u PE sáčků je `}<a parentName="p" {...{
        "href": "/technologie/parametry/rychlouzaviraci-zip/"
      }}><strong parentName="a">{`rychlouzavírací zip`}</strong></a>{`.`}</p>
    <p>{`V naší nabídce naleznete PE `}<strong parentName="p">{`sáčky`}</strong>{` s rozměry od 80×100 mm až po `}<strong parentName="p">{`pytle`}</strong>{` 500×800 mm.`}</p>
    <h2 {...{
      "id": "polypropylenové-folie-hadice-a-polohadice"
    }}>{`Polypropylenové folie, hadice a polohadice`}</h2>
    <p>{`Vyrábíme `}<a parentName="p" {...{
        "href": "/technologie/materialy/polypropylen-pp/"
      }}><strong parentName="a">{`polypropylenové (PP)`}</strong></a>{` `}<a parentName="p" {...{
        "href": "/technologie/ukazky/folie/"
      }}><strong parentName="a">{`folie`}</strong></a>{`, `}<a parentName="p" {...{
        "href": "/technologie/ukazky/hadice-a-polohadice/"
      }}><strong parentName="a">{`hadice a polohadice`}</strong></a></p>
    <p><strong parentName="p">{`PP folie`}</strong>{` slouží především pro další zpracování a to hlavně na výrobu `}<a parentName="p" {...{
        "href": "/technologie/ukazky/sacky-a-pytle"
      }}><strong parentName="a">{`sáčků`}</strong></a>{` a `}<a parentName="p" {...{
        "href": "/technologie/ukazky/prirezy"
      }}><strong parentName="a">{`přířezů`}</strong></a>{`. Dále je možno použít folie k `}<a parentName="p" {...{
        "href": "/technologie/ukazky/laminace/"
      }}><strong parentName="a">{`laminaci`}</strong></a>{` výrobků a velice často též pro `}<strong parentName="p">{`balení`}</strong>{` – např. využití v `}<strong parentName="p">{`potravinářství`}</strong>{` nebo pro `}<strong parentName="p">{`balení časopisů`}</strong>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      